import { GET_TICKET, CLEAR_STORE, APPEAL_CREATED } from "../actions/types";

const initialState = {
  ticket: {},
  appeal: null,
};

export enum CountryCode {
  DE = "DE",
  CH = "CH",
}

export function convertStringToCountryCode(value: string): CountryCode | undefined {
  return CountryCode[value as keyof typeof CountryCode];
}

export interface Ticket {
  id: number;
  number: number;
  licensePlate: string;
  reminderDeadline: Date;
  amount: number;
  totalGasStationAmount: number;
  reminderFee: number;
  additionalCost: number;
  parkingZone: ParkingZone;
  imageUrls: [""];
  date: Date;
  paymentPeriod: number;
  objectionEnabled: boolean;
  appeal: {};
  status: string;
  address: string;
  paymentDate: Date;
  paidAmount: number;
  gasStationZone: GasStationZone;
  gasStationTicketDetails: GasStationTicketDetails;
  issueDate: Date;
  documentLanguage: string;
  ticketSeriesCountryCode: string;
  ticketViolationType: TicketViolationType;
}

export enum TicketStatus {
  PAID = "PAID",
  STOPPED = "STOPPED"
}

export enum TicketViolationType {
  CH_PARKING_VIOLATION_ZPO= "CH_PARKING_VIOLATION_ZPO",
  CH_PARKING_VIOLATION_NON_ZPO= "CH_PARKING_VIOLATION_NON_ZPO",
  CH_DRIVE_OFF= "CH_DRIVE_OFF",
  DE_DRIVE_OFF= "DE_DRIVE_OFF",
}

export interface GasStationZone {
  id: number;
  number: number;
  name: string;
  powerOfAttorneyName: string;
  powerOfAttorney: string;
  countryCode: CountryCode;
}
export interface GasStationTicketDetails {
  fuel: string | null;
  fuelVolume: number;
  amount: number;
  category: string;
  pillar: number;
}
export interface TicketLogin {
  number: string;
  licensePlate: string;
}

export interface ParkingZone {
  id: number;
  parkingZoneText: string;
  countryCode: CountryCode;
}

export interface OrderBill {
  firstName: string | undefined;
  lastName: string | undefined;
  street: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
}

export interface Objection {
  firstName: string | undefined;
  lastName: string | undefined;
  complaintText: string | undefined;
  email: string | undefined;
  parkingZoneId: number;
  ticketId?: number;
  gasStationZoneId?: number;
}

export interface TicketState {
  ticket: Ticket;
  appeal: {};
}

const ticketReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TICKET:
      return {
        ...state,
        ticket: action.payload,
      };
    case CLEAR_STORE: {
      return {
        ...state,
        ticket: {},
        appeal: null,
      };
    }
    case APPEAL_CREATED: {
      return {
        ...state,
        appeal: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ticketReducer;
